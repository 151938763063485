import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AxiosError } from 'axios';
import { useReduxDispatch } from 'Helpers';

import { useMsal } from '@azure/msal-react';
import { datadogRum } from '@datadog/browser-rum';
import { LOGOUT, SET_TOKENS } from 'Hooks/useAuthHandler/_constants_/authHandlerConstants';
import { Token } from 'Hooks/useAuthHandler/state/_types/Token';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import { User } from 'Models/User/_types_/User';
import { userActions } from 'Store/actions/user.actions';
import {
  transformUser,
  useGetProfile,
} from 'UserAuthentication/_LoginUserServices/hooks/authentication/useGetProfile';
import {
  MapTokenError,
  useMapMicrosoftTokenToProfileToken,
} from 'UserAuthentication/_LoginUserServices/hooks/authentication/useMapMicrosoftTokenToProfileToken';
import clearStorage from 'UserAuthentication/Login/LoginButton/helpers/clearStorage';

export const useLoginUser = (
  microsoftToken: string | undefined,
  manualCall?: boolean,
): {
  fetchMapToken: () => void;
  user: User | undefined;
  isLoading: boolean;
  errors: {
    tokenError: MapTokenError | AxiosError | undefined;
    profileError: AxiosError | undefined;
  };
  message?: string;
} => {
  const dispatch = useReduxDispatch();
  const { authDispatch } = useAuthState();
  const navigate = useNavigate();
  const { instance } = useMsal();

  const [mapTokenError, setMapTokenError] = useState<MapTokenError | undefined>();
  const [previousUrl, setPreviousUrl] = useState<string | undefined>();
  const [aditionalMessage, setAditionalMessage] = useState<string | undefined>();

  useEffect(() => {
    const { pathname, search } = window.location;

    const clearUrl = pathname.includes('SUP-')
      ? '/' + pathname.split('/').slice(2).join('/')
      : pathname;

    if (clearUrl !== '/login') {
      setPreviousUrl(clearUrl + search);
    }
  }, []);

  const [fetchMapToken, { data: tokenData, error: tokenError, loading: tokenLoading }] =
    useMapMicrosoftTokenToProfileToken(microsoftToken ?? '');

  const startFlow = useCallback(() => {
    fetchMapToken();
  }, [fetchMapToken]);

  useEffect(() => {
    if (microsoftToken && tokenError) {
      if (
        tokenError.response?.status === 400 ||
        tokenError.response?.status === 401 ||
        tokenError.response?.status === 403
      ) {
        setAditionalMessage('Probably there was a problem with your cache. Please try again.');
        clearStorage();
        instance.clearCache();
      }
      const error: MapTokenError = {
        message: tokenError.message,
        token: microsoftToken,
        name: 'MapTokenError',
        code: tokenError.code,
      };
      setMapTokenError(error);
      return;
    }
    if (microsoftToken && !tokenData && !tokenLoading && !manualCall) {
      startFlow();
    }
  }, [microsoftToken, tokenData, tokenLoading, startFlow, tokenError, manualCall, instance]);

  const adaptedToken = useMemo((): Token | undefined => {
    if (!microsoftToken) {
      return;
    }

    if (tokenLoading) {
      return;
    }

    if (tokenError) {
      datadogRum.addError(
        `Login error. Error mapping token ${microsoftToken} ${JSON.stringify(tokenError, null)}`,
        tokenError,
      );
      return;
    }

    if (!tokenData) {
      return;
    }

    return {
      accessToken: tokenData.access_token,
      refreshToken: tokenData.refresh_token,
      idToken: microsoftToken,
      expires: new Date().getTime() + 1000 * tokenData.expires_in,
      provider: 'AD',
      sid: 'AD-' + tokenData.email,
      remember: false,
      hasADLogin: tokenData.adInfo.hasADLogin,
      email: tokenData.email,
      inviteStatus: tokenData.invite_status,
    };
  }, [microsoftToken, tokenData, tokenError, tokenLoading]);

  const [fetchGetProfile, { data: profileData, error: profileError, loading: profileLoading }] =
    useGetProfile(adaptedToken?.accessToken ?? '');

  useEffect(() => {
    if (adaptedToken && !profileData && !profileLoading) {
      fetchGetProfile();
      authDispatch({ type: SET_TOKENS, tokens: adaptedToken });
    }
  }, [adaptedToken, fetchGetProfile, authDispatch, profileData, profileLoading]);

  const adaptedProfile = useMemo(() => {
    if (profileLoading) {
      return;
    }

    if (profileError) {
      datadogRum.addError('Login error. User could not be casted.', profileError);
      authDispatch({ type: LOGOUT });
      dispatch(userActions.deleteUser());
      return;
    }

    if (!profileData) {
      return;
    }

    return transformUser(profileData);
  }, [profileData, profileError, profileLoading, authDispatch, dispatch]);

  useEffect(() => {
    if (adaptedProfile) {
      dispatch(userActions.setUser(adaptedProfile));
      if (previousUrl) {
        navigate(previousUrl);
      } else {
        navigate('/');
      }
    }
  }, [adaptedProfile, dispatch, navigate, previousUrl]);

  const isLoading = useMemo(() => {
    return tokenLoading || profileLoading;
  }, [tokenLoading, profileLoading]);

  return {
    fetchMapToken: startFlow,
    user: adaptedProfile,
    isLoading,
    errors: {
      tokenError: mapTokenError ?? tokenError ?? undefined,
      profileError,
    },
    message: aditionalMessage,
  };
};
