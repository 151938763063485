import { ReactElement, useState, MouseEvent, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Link, useLocation } from 'react-router-dom';

import { Box } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { useMsal } from '@azure/msal-react';
import { Loading } from '@bestseller-bit/frontend-community.components.loading';
import { useGetFeatureFlag } from 'FeatureFlags/FeatureFlags';
import { LOGOUT } from 'Hooks/useAuthHandler/_constants_/authHandlerConstants';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import { UserReducerType } from 'Models/User/_types_/UserReducerType';
import { RootReducerState } from 'Store/reducers/_types_/RootReducer';
import { DataCacheState, Vendor } from 'Store/reducers/dataCache.reducer';
import { useRevokeAccess } from 'UserAuthentication/_LoginUserServices/hooks/authentication/useRevokeAccess';
import SwitchProfileList from 'UserAuthentication/Functionalities/ChooseProfile';
import { Profile } from 'UserAuthentication/Functionalities/ChooseProfile/_types/ProfileResponse';

import classes from './UserInfo.module.scss';

const StylizedButtonForMenu = (props: {
  label: string;
  handleClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}): ReactElement => {
  return (
    <Button
      sx={{ textTransform: 'capitalize', color: 'black', p: 0, justifyContent: 'flex-start' }}
      onClick={props.handleClick}
    >
      {props.label}
    </Button>
  );
};

export const UserInfo = (): ReactElement => {
  const { instance } = useMsal();

  const { authDispatch } = useAuthState();
  const { authState } = useAuthState();

  const [anchorForProfileMenu, setAnchorForProfileMenu] = useState<null | HTMLElement>(null);
  const [anchorForProfilesList, setAnchorForProfilesList] = useState<null | HTMLElement>(null);

  const openProfileMenu = useMemo((): boolean => {
    return Boolean(anchorForProfileMenu);
  }, [anchorForProfileMenu]);

  const openProfilesList = useMemo((): boolean => {
    return Boolean(anchorForProfilesList);
  }, [anchorForProfilesList]);

  const handleProfileMenuClick = useCallback((event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorForProfileMenu(event.currentTarget);
  }, []);

  const handleProfileMenuClose = useCallback((): void => {
    setAnchorForProfileMenu(null);
  }, []);

  const handleProfilesListClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorForProfilesList(event.currentTarget);
    },
    [setAnchorForProfilesList],
  );

  const handleProfilesListClose = useCallback(() => {
    setAnchorForProfilesList(null);
  }, [setAnchorForProfilesList]);

  const user = useSelector((root: RootReducerState): UserReducerType => root.userReducer);
  const dataCacheState = useSelector(
    (root: RootReducerState): DataCacheState => root.dataCacheState,
  );

  const location = useLocation();

  const [loadingSwitchingProfile, setLoadingSwitchingProfile] = useState(false);

  const tokens = useMemo(() => authState.tokens, [authState.tokens]);

  const [revokeAccess] = useRevokeAccess(tokens?.accessToken ?? '');

  const stateProfiles = useMemo((): Profile[] => {
    return authState.profiles ?? [];
  }, [authState.profiles]);

  const getFeatureFlag = useGetFeatureFlag();
  const flag = useFlags();

  const shouldRenderMultipleProfilesFeatures = useMemo((): boolean => {
    if (flag[getFeatureFlag('multiple-ad-users')] === false) {
      return false;
    }
    if (stateProfiles.length === 0 || user.user?.isBestsellerUser || tokens?.provider !== 'AD') {
      return false;
    }
    return true;
  }, [flag, getFeatureFlag, stateProfiles.length, tokens?.provider, user.user?.isBestsellerUser]);

  const shouldRenderSwitchProfile = useMemo(
    (): boolean => Boolean(shouldRenderMultipleProfilesFeatures && stateProfiles.length > 1),
    [shouldRenderMultipleProfilesFeatures, stateProfiles.length],
  );

  if (!user?.user || !user.user.fullName) {
    return <></>;
  }
  const splitted = user.user.fullName.split(' ');
  const first = splitted[0][0];
  const second = splitted.length > 1 ? splitted[1][0] : '';

  if (loadingSwitchingProfile) {
    return (
      <Box p={1}>
        <Loading circularProgressColor="white" />
      </Box>
    );
  }

  return (
    <>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleProfileMenuClick}>
        <Avatar>
          {first}
          {second}
        </Avatar>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorForProfileMenu}
        keepMounted
        open={openProfileMenu}
        onClose={handleProfileMenuClose}
        classes={{ list: classes.menu }}
      >
        <div className={[classes.menuItem, classes.nameContainer].join(' ')}>
          {user.user.fullName}
          <div>
            {user.user.canChangeVendorMdmIdentifier && (
              <div className={classes.vendorInfo}>
                <span>Vendor:</span>{' '}
                <span>
                  {dataCacheState.vendors.loading ? (
                    <span>Loading...</span>
                  ) : (
                    (dataCacheState.vendors.vendors.find(
                      (x: Vendor): boolean =>
                        x.vendorMdmIdentifier === user.user?.vendorMdmIdentifier,
                    )?.vendorName ?? <span>All vendors</span>)
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
        {user.user.canChangeVendorMdmIdentifier && (
          <MenuItem onClick={handleProfileMenuClose} classes={{ root: classes.linkContainer }}>
            <NavLink
              to={{ pathname: '/setvendor' }}
              className={classes.menuItem}
              state={{ from: location.pathname }}
            >
              <StylizedButtonForMenu label="Set vendor" />
            </NavLink>
          </MenuItem>
        )}
        {shouldRenderSwitchProfile && (
          <MenuItem classes={{ root: classes.linkContainer }}>
            <>
              {' '}
              <Box className={classes.menuItem}>
                <StylizedButtonForMenu
                  label="Switch account"
                  handleClick={handleProfilesListClick}
                />
              </Box>
              <Menu
                anchorEl={anchorForProfilesList}
                open={openProfilesList}
                onClose={handleProfilesListClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <SwitchProfileList
                  closeProfileMenu={handleProfileMenuClose}
                  closeProfilesList={handleProfilesListClose}
                  setLoadingSwitchProfile={(value: boolean) => setLoadingSwitchingProfile(value)}
                />
              </Menu>
            </>
          </MenuItem>
        )}
        {shouldRenderMultipleProfilesFeatures && (
          <MenuItem onClick={handleProfileMenuClose} classes={{ root: classes.linkContainer }}>
            <Link
              to="/manageaccounts"
              state={{ from: location.pathname }}
              className={classes.menuItem}
            >
              <StylizedButtonForMenu label="Manage accounts" />
            </Link>
          </MenuItem>
        )}
        <MenuItem onClick={handleProfileMenuClose} classes={{ root: classes.linkContainer }}>
          {tokens?.provider !== 'AD' ? (
            <div
              style={{ color: 'black', textDecoration: 'none' }}
              className={classes.menuItem}
              onClick={(): void => {
                if (tokens?.accessToken) {
                  revokeAccess();
                }
                authDispatch({ type: LOGOUT });
              }}
            >
              <StylizedButtonForMenu label="Logout" />
            </div>
          ) : (
            <div
              style={{ color: 'black', textDecoration: 'none' }}
              className={classes.menuItem}
              onClick={(): void => {
                if (tokens?.accessToken) {
                  revokeAccess();
                }
                instance.logout();
                sessionStorage.clear();
                authDispatch({ type: LOGOUT });
              }}
            >
              <StylizedButtonForMenu label="Logout from AD" />
            </div>
          )}
        </MenuItem>
      </Menu>
    </>
  );
};
