import { properties } from 'Helpers';
import { LDFlagSet } from 'launchdarkly-js-sdk-common';

import { MenuElement } from 'Components/UI/HeaderPage/_types_/MenuElement';
import { getEnumKeyByEnumValue } from 'Helpers/util';
import { OrderStateEnum } from 'Models/Orders/_types_/OrderStateType';

import { PERMISSION } from './permissions.constants';

export const CONSTRUCTION_TO = '/construction';
export const INFORMATION_TO = '/information';
export const ORDERS_TO = '/orders';
export const ORDERS_UPLOAD_TO = '/orders/order-attachments/upload';
export const STYLES_TO = '/styles';
export const PACKING_NOTE_TO = '/packingnote';
export const PACKING_NOTE_DETAIL_TO = '/packingnote/detail';
export const CREATE_PACKING_NOTE_TO = '/packingnote/create';
export const DASHBOARD_TO = '/dashboard';
export const DASHBOARD_ALTERNATE_ROUTES = ['/', DASHBOARD_TO];
export const LOGIN_TO = '/login';
export const AD_INVITE_TO = '/adlogin/invite';
export const AD_LINKED_TO = '/adlogin/adlinked';
export const FAST_CONFIRM_TO = '/fastconfirm';
export const INVOICE_TO = '/invoices';
export const INBOUND_INVOICE_TO = '/invoices/inbound';
export const OUTBOUND_INVOICE_TO = '/invoices/outbound';
export const SAMPLE_PROFORMA_INVOICE_TO = '/invoices/sample/proforma';
export const CREATE_SAMPLE_PROFORMA_INVOICE_TO = '/invoices/sample/proforma/create';
export const SAMPLE_COMMERCIAL_INVOICE_TO = '/invoices/sample/commercial';
export const CREATE_SAMPLE_COMMERCIAL_INVOICE_TO = '/invoices/sample/commercial/create';
export const CLAIM_TO = '/claim/claims';
export const CREDIT_NOTE_TO = '/claim/credit-notes';
export const ORDERS = 'ORDERS';
export const DASHBOARD = 'DASHBOARD';
export const INFORMATION = 'INFORMATION';
export const PACKING_NOTES = 'PACKING NOTES';
export const SOURCING_SITE = 'SOURCING SITE';
export const FAST_CONFIRM = 'FAST CONFIRM';

export const SOURCING_SITE_URL =
  'https://mybestseller.sharepoint.com/teams/BESTSELLERGlobalSourcing-SupplierWindow2';

export const CLOSET = '3D-DESIGN';
export const CLOSET_URL = 'https://www.clo-set.com/';
export const GUIDE = 'User Guide';
export const GUIDE_URL = `${
  (properties.REACT_APP_DEPLOY_VERSION ?? '').indexOf('VP-') > -1
    ? properties.REACT_APP_DEPLOY_VERSION + '/'
    : ''
}assets/pdf/VENDOR_PORTAL_USER_GUIDE-31.01.2023.pdf`;
export const LOGIN_GUIDE_URL = `${
  (properties.REACT_APP_DEPLOY_VERSION ?? '').indexOf('VP-') > -1
    ? properties.REACT_APP_DEPLOY_VERSION + '/'
    : ''
}assets/pdf/VENDOR_PORTAL_LOGIN_GUIDE-08.05.2024.pdf`; // Holder

export const DASHBOARD_STATE_FILTERS: OrderStateEnum[] = [
  OrderStateEnum.NEW,
  OrderStateEnum.AWAITING_VENDOR,
];

const fastConfirmMenu: MenuElement = {
  key: 0,
  name: FAST_CONFIRM,
  link: FAST_CONFIRM_TO,

  activeClassName: 'activeRoute',
  permission: PERMISSION.VP_ORDER_CONFIRMATION,
};

const useOrdersMenu = () => {
  const filters = Object.entries(DASHBOARD_STATE_FILTERS)
    .map(([_, val]) => `orderStates=${getEnumKeyByEnumValue(OrderStateEnum, val)}`)
    .join('&');

  return () => ({
    key: 1,
    name: ORDERS,
    link: `${ORDERS_TO}${filters}}`,
    activeClassName: 'activeRoute',
    permission: PERMISSION.VP_VIEW_ORDER,
  });
};

const dashboardMenu: MenuElement = {
  key: 2,
  name: DASHBOARD,
  link: DASHBOARD_TO,
  activeClassName: 'activeRoute',
  permission: PERMISSION.VP_VIEW_DASHBOARD,
};

const newsMenu: MenuElement = {
  key: 3,
  name: INFORMATION,
  link: INFORMATION_TO,
  activeClassName: 'activeRoute',
  permission: PERMISSION.VP_VIEW_NEWS,
};

const usePackingNotesMenu = (): (() => MenuElement) => {
  return () => ({
    key: 4,
    name: PACKING_NOTES,
    link: PACKING_NOTE_TO,
    activeClassName: 'activeRoute',
    permission: PERMISSION.VP_PACKING_NOTE_VIEW,
  });
};

export const useMenus = (): ((_ff: LDFlagSet) => MenuElement[]) => {
  const ordersMenu = useOrdersMenu();
  const packingNotesMenu = usePackingNotesMenu();
  return () => [fastConfirmMenu, dashboardMenu, ordersMenu(), packingNotesMenu(), newsMenu];
};
