import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Box, Button, LinearProgress, Link, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';

import { useReduxDispatch } from 'Helpers';

import { BrowserAuthError, EventMessage } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Loading } from '@bestseller-bit/frontend-community.components.loading';
import { datadogRum } from '@datadog/browser-rum';
import { loginRequest } from 'Config/adAuthConfig';
import { LOGOUT } from 'Hooks/useAuthHandler/_constants_/authHandlerConstants';
import { useAuthState } from 'Hooks/useAuthHandler/state/useAuthState';
import { toastActions } from 'Store/actions/toast.actions';
import { userActions } from 'Store/actions/user.actions';
import useAutoLogin from 'UserAuthentication/_LoginUserServices/hooks/authentication/useAutoLogin';
import { useLoginUser } from 'UserAuthentication/_LoginUserServices/hooks/authentication/useLoginUser';
import { MapTokenError } from 'UserAuthentication/_LoginUserServices/hooks/authentication/useMapMicrosoftTokenToProfileToken';
import ContactSupportDialog from 'UserAuthentication/Login/LoginButton/ContactSupportDialog';
import FlowFailedDialog from 'UserAuthentication/Login/LoginButton/FlowFailedDialog';

const LoginButton = (props: {
  setError: Dispatch<SetStateAction<string>>;
  height?: string;
  setLoading?: (loading: boolean) => void;
  goToCredentialsForm: (() => void) | undefined;
}): ReactElement => {
  const { instance } = useMsal();
  const [microsoftToken, setMicrosoftToken] = useState<string | undefined>();
  const { user, errors, isLoading, fetchMapToken, message } = useLoginUser(microsoftToken, true); // true for stablishing a manualCall and avoid double calls in hook
  const { automaticSignInState } = useAutoLogin();
  const { authDispatch } = useAuthState();
  const dispatch = useReduxDispatch();
  const [loading, setLoading] = useState(false);
  const [eventCallbackId, setEventCallbackId] = useState<string | undefined>();
  const [userCancelledFlow, setUserCancelledFlow] = useState(false);
  const [popupOpened, setPopupOpened] = useState(false);
  const [showContactSupportDialog, setShowContactSupportDialog] = useState(false);
  const [errorToken, setErrorToken] = useState<string | undefined>();
  const [callFlow, setCallFlow] = useState(false);

  useEffect(() => {
    if (eventCallbackId !== undefined) {
      return;
    }

    const callbackId = instance.addEventCallback((message: EventMessage) => {
      if (message.eventType === 'msal:popupOpened') {
        setPopupOpened(true);
        return;
      }
      if (message.eventType === 'msal:loginFailure' || message.eventType === 'msal:loginSuccess') {
        setPopupOpened(false);
      }
    });
    setEventCallbackId(callbackId ?? undefined);
    return () => {
      if (eventCallbackId === undefined) {
        return;
      }
      instance.removeEventCallback(eventCallbackId);
    };
  }, [dispatch, eventCallbackId, instance, props]);

  useEffect(() => {
    if (props.setLoading !== undefined) {
      props.setLoading(loading);
    }
  }, [loading, props]);

  const handleLogin = useCallback(() => {
    setLoading(true);

    instance
      .loginPopup(loginRequest)
      .then((response) => {
        if (instance.getActiveAccount() === null) {
          instance.setActiveAccount(response.account);
        }
        setMicrosoftToken(response.idToken);
        setCallFlow(true);
      })
      .catch((e) => {
        const browserAuthError = e as BrowserAuthError;
        if (browserAuthError !== null && browserAuthError.errorCode === 'user_cancelled') {
          setPopupOpened(false);
          setUserCancelledFlow(true);
          setLoading(false);
          return;
        }
        setLoading(false);
        dispatch(toastActions.setErrorMessage('Something went wrong: ' + e.message));
      });
  }, [dispatch, instance]);

  useEffect(() => {
    if (callFlow && microsoftToken) {
      fetchMapToken();
      if (errors.tokenError || errors.profileError) {
        const errorInMapToken = errors.tokenError as MapTokenError;
        const errorInProfile = errors.profileError;
        if (errorInMapToken?.code === '403' || errorInMapToken?.message.search('403') !== -1) {
          setErrorToken(errorInMapToken?.token ?? '');
          setShowContactSupportDialog(true);
          datadogRum.addAction('Showing contact support dialog');
        }
        authDispatch({ type: LOGOUT });
        dispatch(userActions.deleteUser());
        dispatch(
          toastActions.setErrorMessage(
            'Something went wrong: ' + errorInMapToken?.message + '. ' + errorInProfile?.message,
          ),
        );
        setLoading(false);
      }
    }
    setPopupOpened(false);
    setCallFlow(false);
  }, [
    authDispatch,
    callFlow,
    dispatch,
    errors.profileError,
    errors.tokenError,
    microsoftToken,
    user,
    fetchMapToken,
  ]);

  const handleClick = useCallback(() => {
    props.setError('');
    handleLogin();
  }, [handleLogin, props]);

  const displayErrorMessage = useMemo(() => {
    if (errors.profileError || errors.tokenError || (!user && microsoftToken)) {
      setLoading(false);
      return true;
    }
    return false;
  }, [errors.profileError, errors.tokenError, microsoftToken, user]);

  if (isLoading || automaticSignInState === 'PENDING' || automaticSignInState === 'NOT_STARTED') {
    if (props.setLoading) {
      props.setLoading(true);
    }
    return (
      <Box>
        <Loading message="Trying to log you in..." />
        <Typography variant="body2" sx={{ opacity: 0.8 }} mt={2}>
          <span>If you have been stucked in this page for too long, you should try to &nbsp;</span>
          <Link
            color="info.main"
            onClick={() => {
              authDispatch({ type: LOGOUT });
              instance.logout();
            }}
          >
            logout from Microsoft
          </Link>
          <span>&nbsp;and try to log in again.</span>
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <ContactSupportDialog
        open={showContactSupportDialog}
        onClose={() => {
          setShowContactSupportDialog(false);
        }}
        token={errorToken}
      />
      <FlowFailedDialog
        open={userCancelledFlow}
        onClose={() => setUserCancelledFlow(false)}
        goToCredentialsForm={props.goToCredentialsForm}
      />
      <Grid container flexDirection="column">
        {popupOpened && (
          <Typography variant="body2">Please finish logging in in the popup...</Typography>
        )}
        <Grid flex={1}>
          <Button
            disabled={loading || popupOpened}
            variant="contained"
            disableElevation
            sx={{ width: '100%', color: 'white', height: props.height ?? '100%' }}
            onClick={handleClick}
          >
            LOG IN WITH MICROSOFT
          </Button>
        </Grid>
        <Grid flex={1}>{loading && <LinearProgress />}</Grid>
        {displayErrorMessage && (
          <Grid mt={1}>
            <Typography variant="body2" color="error">
              {message ?? 'Something went wrong. Please try again later.'}
            </Typography>
          </Grid>
        )}
        {user && instance.getAllAccounts().length > 0 && (
          <Grid mt={1}>
            <Button
              disabled={loading}
              variant="outlined"
              disableElevation
              sx={{ width: '100%', height: '50px' }}
              onClick={() => {
                authDispatch({ type: LOGOUT });
                instance.logout();
              }}
            >
              LOG OUT OF MICROSOFT
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default LoginButton;
